import { MarkerWithPopup } from '@streetferret/map-web';
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from '@streetferret/ui-web/card';
import { DateTime } from '@streetferret/ui-web/typography/date-time';

export interface RouteStartEndMarkersProps {
  startDate: Date;

  startPoint?: {
    lat: number;
    lng: number;
  };
  endPoint?: {
    lat: number;
    lng: number;
  };
}
export function RouteStartEndMarkers({
  startDate,
  startPoint,
  endPoint,
}: RouteStartEndMarkersProps) {
  return (
    <>
      {startPoint && <RouteStartMarker {...startPoint} startDate={startDate} />}
      {endPoint && <RouteEndMarker {...endPoint} />}
    </>
  );
}

interface RouteStartMarkerProps {
  lat: number;
  lng: number;
  startDate: Date;
}
/**
 * Marker to show the start of a route or journey.
 */
export function RouteStartMarker({
  lat,
  lng,
  startDate,
}: RouteStartMarkerProps) {
  return (
    <MarkerWithPopup
      lat={lat}
      lng={lng}
      marker={
        <div className="relative size-4 bg-green-500 dark:bg-green-700 border-2 border-foreground rounded-full cursor-pointer" />
      }
      popup={
        <CardHeader className="min-w-[48px]">
          <CardTitle className="text-base">Start</CardTitle>
          <CardDescription>
            <DateTime date={startDate} />
          </CardDescription>
        </CardHeader>
      }
    />
  );
}

interface RouteEndMarkerProps {
  lat: number;
  lng: number;
}

/**
 * Marker to show the end of a route or journey.
 */
export function RouteEndMarker({ lat, lng }: RouteEndMarkerProps) {
  return (
    <MarkerWithPopup
      lat={lat}
      lng={lng}
      marker={
        <div className="relative size-4 bg-muted border-2 border-muted-foreground rounded-full cursor-pointer" />
      }
      popup={
        <CardHeader className="min-w-[48px]">
          <CardTitle className="text-base">Finish</CardTitle>
        </CardHeader>
      }
    />
  );
}
