'use client';
import { type RouterOutputs } from '@streetferret/api-client/client';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@streetferret/ui-web/avatar';
import { DateTime } from '@streetferret/ui-web/typography/date-time';
import { cn } from '@streetferret/utils';
import { differenceInMinutes } from 'date-fns';
import { useMemo, useState } from 'react';
import { Marker, Popup } from 'react-map-gl';

export interface LivePositionMarkerProps {
  position: RouterOutputs['journey']['tracking']['livePosition'];
  user: {
    firstname: string | null;
    lastname: string | null;
    profile: string | null;
  };
}

export function LivePositionMarker({
  position,
  user,
}: LivePositionMarkerProps) {
  const [popup, setPopup] = useState(false);
  const initials = useMemo(
    () => `${user.firstname?.at(0)}${user.lastname?.at(0)}`,
    [user.firstname, user.lastname],
  );
  const latLng = useMemo(() => {
    if (!position) {
      return undefined;
    }
    return {
      lat: position.point.coordinates[1],
      lng: position.point.coordinates[0],
    };
  }, [position]);
  const lastUpdate = useMemo(() => {
    if (!position) {
      return undefined;
    }
    return new Date(position.time);
  }, [position]);
  const isActive = useMemo(() => {
    if (!position) {
      return false;
    }
    if (!lastUpdate) {
      return false;
    }
    return differenceInMinutes(new Date(), lastUpdate) < 45;
  }, [position, lastUpdate]);

  return (
    <>
      {latLng && (
        <Marker
          longitude={latLng.lng}
          latitude={latLng.lat}
          anchor="bottom"
          onClick={e => {
            // prevent closing the popup when clicking on the marker
            e.originalEvent.stopPropagation();
            setPopup(true);
          }}>
          <div className="relative h-7 w-7" data-testid="live-position-marker">
            <div
              className={cn(
                'absolute inset-0 rounded-full',
                isActive && 'animate-ping bg-red-500',
              )}
              style={{ animationDuration: '2s' }}
            />
            <Avatar className="border-2 !h-7 !w-7 cursor-pointer">
              <AvatarImage
                src={user.profile ?? ''}
                alt={(user.firstname ?? '') + ' ' + (user.lastname ?? '')}
              />
              <AvatarFallback
                className={cn(
                  isActive
                    ? '!bg-foreground !text-background'
                    : '!bg-foreground/70 !text-background/80',
                )}>
                {initials}
              </AvatarFallback>
            </Avatar>
          </div>
        </Marker>
      )}
      {popup && latLng ? (
        <Popup
          anchor="top"
          longitude={latLng.lng}
          latitude={latLng.lat}
          closeButton={false}
          onClose={() => setPopup(false)}>
          <div
            className="px-2 py-1"
            role="dialog"
            aria-labelledby="live-position-update-title">
            <div className="font-bold" id="live-position-update-title">
              Last Position Update
            </div>
            <div>
              <span>{latLng.lng}</span>
              <span>, </span>
              <span>{latLng.lat}</span>
            </div>
            {lastUpdate && <DateTime date={lastUpdate} />}
          </div>
        </Popup>
      ) : null}
    </>
  );
}
