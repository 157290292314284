'use client';

import { api } from '@streetferret/api-client/client';
import { JourneyLiveProgressLayer } from '@streetferret/map-web';
import { Source } from 'react-map-gl';
import { LivePositionMarker } from './live-position-marker';

const REFETCH_INTERVAL_MS = 30_000;
interface JourneyLiveLayersProps {
  journeyId: bigint;
  user: {
    firstname: string | null;
    lastname: string | null;
    profile: string | null;
  };
}
/**
 *
 * React-Map-GL component to display a journey user's live position.
 */
export function JourneyLiveLayers({ journeyId, user }: JourneyLiveLayersProps) {
  const latestPositionQuery = api.journey.tracking.livePosition.useQuery(
    {
      journeyId: BigInt(journeyId),
    },
    {
      refetchInterval: REFETCH_INTERVAL_MS,
    },
  );
  const latestPosition = latestPositionQuery.data;

  const liveProgressQuery = api.journey.tracking.liveProgress.useQuery(
    {
      journeyId: BigInt(journeyId),
    },
    {
      refetchInterval: REFETCH_INTERVAL_MS,
    },
  );

  return (
    <>
      {liveProgressQuery.data && (
        <Source
          id="live-progress"
          type="geojson"
          data={liveProgressQuery.data.geojson}>
          <JourneyLiveProgressLayer id="live-progress" />
        </Source>
      )}

      <LivePositionMarker position={latestPosition} user={user} />
    </>
  );
}
