'use client';

import { formatDateTimeLong } from '@streetferret/utils';

interface DateTimeProps {
  date?: Date;
  className?: string;
  withTz?: boolean;
}
export function DateTime({ date, className, withTz }: DateTimeProps) {
  return (
    <span className={className}>
      {date
        ? formatDateTimeLong(date, {
            tz: withTz,
          })
        : null}
    </span>
  );
}
